import { Link as GatsbyLink } from "gatsby";
import React from "react";
import { SiInstagram } from "@react-icons/all-files/si/SiInstagram";
import { SiFacebook } from "@react-icons/all-files/si/SiFacebook";
import { facebookLink, instagramLink } from "../../../common/helpers/config";
import Link from "../../UI-Elements/Navigation/Link";

const IgFooter = () => {
  return (
    <footer className="text-primary">
      <div className="flex justify-center mx-auto text-sm">
        <Link className="text-xl pr-2" href={instagramLink} external>
          <SiInstagram />
        </Link>
        <Link className="text-xl pr-2" href={facebookLink} external>
          <SiFacebook />
        </Link>
      </div>
      <div className="flex justify-center mx-auto text-sm">
        <GatsbyLink className="text-sm p-1" to={"/imprint"}>
          Impressum
        </GatsbyLink>
        <GatsbyLink className="text-sm p-1" to={"/"}>
          Webseite
        </GatsbyLink>
      </div>
    </footer>
  );
};

export default IgFooter;
