import React from "react";
import { Link as GatsbyLink } from "gatsby";

export type LinkProps = {
  to?: string
  href?: string;
  external?: boolean;
  className?: string;
  type?: 'text' | 'none' | 'hover' | 'button';
};

const typeStyles = {
  none: 'no-underline',
  hover: 'hover:text-blue-800 hover:prose-a:text-blue-800 no-underline',
  text: 'hover:text-blue-800 hover:prose-a:text-blue-800 underline underline-offset-2',
  button: 'hover:text-blue-800 hover:prose-a:text-blue-800 text-blue-800 hover:text-blue-500',
}

export const getStyle = (type: LinkProps['type'] = 'none') => {
  return typeStyles[type] ?? typeStyles['none'];
}

const Link: React.FC<LinkProps> = ({ children, href, to, external = false, className, type = 'none', ...others }) => {
  const optional = external ? {
    target: "_blank",
    rel: "noopener noreferrer"
  } : {}

  if (to) {
    return <GatsbyLink className={`${typeStyles[type]} ${className ?? ''}`} to={to}>{children}</GatsbyLink>;
  }

  return (
    <a href={href} className={`${typeStyles[type]} ${className ?? ''}`} {...optional} {...others}>{children}</a>
  );
};

export default Link;
