import React from "react";
import { clsx } from "clsx";
import { defaultGradient } from "@common/config/classes";
type Props = {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  children: React.ReactNode;
  className?: string;
  align?: "left" | "center" | "right";
  icon?: React.ReactNode;
  gradient?: string;
};

type LevelConfig = {
  fontSize: string;
  gradient?: string;
};

const defaultConfig: Record<number, LevelConfig> = {
  1: {
    fontSize: "text-4xl",
    gradient: defaultGradient,
  },
  2: {
    fontSize: "text-3xl",
  },
  3: {
    fontSize: "text-2xl",
  },
  4: {
    fontSize: "text-lg",
  },
  5: {
    fontSize: "text-base",
  },
  6: {
    fontSize: "text-base",
  },
};

const HTMLHeading: React.FC<
  Pick<Props, "children" | "level" | "className">
> = ({ children, level = 1, className = "" }) => {
  switch (level) {
    case 1:
      return <h1 className={className}>{children}</h1>;
    case 2:
      return <h2 className={className}>{children}</h2>;
    case 3:
      return <h3 className={className}>{children}</h3>;
    case 4:
      return <h4 className={className}>{children}</h4>;
    case 5:
      return <h5 className={className}>{children}</h5>;
    case 6:
      return <h6 className={className}>{children}</h6>;
  }
};

const Title: React.FC<Props> = ({
  children,
  level = 1,
  className = "",
  align = "center",
  icon,
  gradient,
}) => {
  const defaultClasses = `font-bold tracking-tight md:tracking-normal mb-3 ${
    align ? `text-${align}` : ""
  }`;
  const classes = clsx(
    defaultClasses,
    defaultConfig[level].fontSize,
    className.split(" ")
  );

  return (
    <HTMLHeading className={classes}>
      {icon}
      <span className={gradient || defaultConfig[level].gradient}>
        {children}
      </span>
    </HTMLHeading>
  );
};

export default Title;
