import Logo from "@components/Core/Layout/Logo";
import Title from "@components/UI-Elements/Typography/Title";
import * as React from "react";
import IgFooter from "../../components/Modules/Instragram/ig-footer";
import Exchanges from "../../components/Modules/support/exchanges";
import OtherBenefits from "../../components/Modules/support/others";

// Instagram LandingPage
const IgPage: React.FC = () => {
  return (
    <div className="max-w-screen-sm mx-auto">
      <div className="p-4 grid grid-cols-1 gap-2">
        <div className="flex justify-center items-center">
          <Logo className="w-1/6"/>
        </div>
        <div className="flex justify-center items-center">
          <Title className="text-xl text-center text-einfach-crypto-primary">@einfach_crypto</Title>
          <p></p>
        </div>
        <Exchanges />
        <OtherBenefits />

        <IgFooter />
      </div>
    </div>
  );
};

export default IgPage;
